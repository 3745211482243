import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import breakpoints from "../../../styles/breakpoints"

const RewardsHeroWrapper = styled.div`
  background-size: 100vw 100vw;
  background-position: center bottom;
  background-image: url(//images.ctfassets.net/m2ntaxma6gb6/5J1TX1VkpfYVNU6rClrOMc/6afc6f6837cff10931794dd96bee2444/L_R_Page__449____495_px_.png);
  background-repeat: no-repeat;

  @media (min-width: ${breakpoints.md}){
    background-image: url(//images.ctfassets.net/m2ntaxma6gb6/2WPRRnIxfifuEd36lOEL3w/a147adf1a20db7bbc5f24e20deacafda/L_R_Page_1200X675.png);
    background-size: cover;
    background-position: unset;
  }
`

const RewardsHero = styled.div`
  background-color: transparent;
  padding: 0px 20px 100vw;
  align-items: flex-start;
  margin: 0 auto;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  flex-direction: column;           
  border-bottom: 1px solid #eaeaec;

  @media (min-width: ${breakpoints.md}) {
    padding: 120px 55px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 200px 55px 120px;
  }
`

const TextContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    align-items: flex-start;
    max-width: 495px;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    flex-direction: row;
    width: unset;
  }
`

const Title = styled.h1`
  line-height: 1;
  letter-spacing: 0px;
  margin: 0 0 25px 0;
  font-family: adobe-garamond-pro,serif;
  font-size: 35px;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    font-size: 46px;
    text-align: left;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 60px;
    max-width: 670px;
  }
`

const Text = styled.p`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.33;
  min-width: calc(100% - 120px);
  max-width: 420px;
  margin: 0 auto;
  padding: 0 30px;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
`

const Button = styled(Link)`
  ${ButtonStyleDark}
  width: 100%;
  font-weight: 600;
  padding: 11px 56px;
  margin-bottom: 15px;
  margin-right: 15px;
  max-width: unset;

  @media (min-width: ${breakpoints.md}) {
    width: unset;
  }
`

const CustomHero = (props) => {
  return (
    <>
      <RewardsHeroWrapper>
        <RewardsHero>
          <TextContainer>
            <Title>
              Join The Scalp Squad
            </Title>
            <Text>
            Make your first purchase to auto-enroll and gain access to exclusive discounts every time you shop.
            </Text>
          </TextContainer>
          <ButtonContainer>
            <Button to="/all-products">
              SHOP NOW
            </Button>
            <Button to="/account">
              SIGN IN
            </Button>
          </ButtonContainer>
        </RewardsHero>
      </RewardsHeroWrapper>
    </>
  )
}

export default CustomHero