import React from "react"
import Hero from './body/Hero'
import HowItWorks from "./body/HowItWorks"
import EarnPoints from "./body/EarnPoints"
import HowToUse from "./body/HowToUse";
import Referral from "./body/Referral"
import CustomHero from "./body/CustomHero";

const PageContents = () => {
  let pageBlocks = []

  if (typeof window !== "undefined" && window.localStorage.getItem("ili") === "true") {
    pageBlocks.push(<Hero key={1} />)
  } else {
    pageBlocks.push(<CustomHero key={1}/>)
  }
  pageBlocks.push(<HowItWorks key={2} />)
  pageBlocks.push(<EarnPoints key={3} />)
  pageBlocks.push(<HowToUse key={4} />)
  pageBlocks.push(<Referral key={5} />)
  

  return <React.Fragment>{pageBlocks}</React.Fragment>
}

export default PageContents
